// import { connect } from "react-redux";

// import Login from "../components/login";
// import { login } from "../actions/authenticationActions";
// import getRedirectLocation from "../helpers/getRedirectLocation";

// const mapStateToProps = (state) => ({
//   error: state.auth.error,
//   inProgress: state.auth.inProgress,
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   login: (email, password) => {
//     const redirect = getRedirectLocation(ownProps.location);
//     dispatch(login(email, password, { redirect }));
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../components/login";
import { login, clearAuthError, getUserProfile } from "../actions/authenticationActions";
import getRedirectLocation from "../helpers/getRedirectLocation";
import Cookies from "js-cookie"
import { AUTH_TOKEN } from "../constants/authenticationConstants";
import { ClipLoader } from "react-spinners";

const LoginContainer = () => {
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false)
  const error = useSelector((state) => state.auth.error);
  const inProgress = useSelector((state) => state.auth.inProgress);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = (email, password) => {
    // const redirect = getRedirectLocation(window.location);
    const redirect = "/dashboard";
    dispatch(login(email, password, navigate, { redirect }, setLoader));
  };

  useEffect(() => {
    // Clear the error when the location changes
    dispatch(clearAuthError());
  }, [location, dispatch]);

  useEffect(() => {
    const cookie = Cookies.get(AUTH_TOKEN);
    if (cookie) {
      dispatch(getUserProfile(cookie, navigate, setPageLoader))
    }
  }, [])


  return (
    <>
      {pageLoader ?
        <div style={styles.loaderContainer}>
          <ClipLoader color={"#123abc"} loading={true} size={50} />
          <p style={styles.loadingText}>Redirecting, please wait...</p>
        </div>
        : <Login
          error={error}
          inProgress={inProgress}
          login={handleLogin}
          loader={loader}
        />}
    </>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    textAlign: 'center'
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '16px'
  },
  successText: {
    textAlign: 'center',
    fontSize: '16px'
  }
};

export default LoginContainer;
