import PropTypes from 'prop-types';
import React from "react";

import Cookies from "js-cookie"
import Navigation from "./navigation";
import css from "../../assets/styles/layout/app.module.scss";
import { Outlet } from 'react-router-dom';
import { AUTH_TOKEN } from '../../constants/authenticationConstants';

const { object, bool } = PropTypes;

class AppLayout extends React.Component {
  static propTypes = {
    user: object,
    fullWidth: bool
  };

  render() {
    const authToken = Cookies.get(AUTH_TOKEN);

    const { user, children, fullWidth } = this.props;
    return (
      <div className={css.layout}>
        <Navigation user={user} authToken={authToken} />
        <div className={fullWidth ? css.contentFullWidth : css.content}>
          {/* {children} */}
          <Outlet />
        </div>
      </div>
    );
  }
}

export default AppLayout;
