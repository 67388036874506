import React from 'react'
import { useNavigate } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

export default function PageNotFound() {

  const navigate = useNavigate()

  setTimeout(() => {
    navigate("/")
  }, 1000);

  return (
    <div style={styles.loaderContainer}>
      <ClipLoader color={"#123abc"} loading={true} size={50} />
      <p style={styles.loadingText}>Page Not Found, please wait...</p>
    </div>
  )
}


const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center'
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '16px'
  },
  successText: {
    textAlign: 'center',
    fontSize: '16px'
  }
};