// import axios from "axios";
// import ReactOnRails from "react-on-rails";

// export default function generateApiClient(getState) {
//   const state = getState();

//   const headers = ReactOnRails.authenticityHeaders({
//     "Accept": "application/json",
//     "Content-Type": "application/json",
//     "X-BasicPassword": state.envContext.data.basicPassword,
//   });

//   if (state.auth.data && state.auth.data.token) {
//     headers["Authorization"] = `Bearer ${state.auth.data.token}`;
//   } else {
//     headers["Authorization"] = "";
//   }

//   return axios.create({
//     timeout: 20000,
//     headers: headers,
//     responseType: "json"
//   });
// }


// http://localhost:5000/account_activations/5l-Odontk63HoKITBJY4oQ/edit?email=daljeet%2Bsingh%2B1001%40trigvent.com

// import axios from "axios";


// const apiUrl = process.env.REACT_APP_DOMAIN_URL;

// export default function generateApiClient(getState) {
//   const state = getState();

//   console.log("apiUrl",apiUrl);


//   // Headers
//   const headers = {
//     "Accept": "application/json",
//     "Content-Type": "application/json",
//   };

//   if (state?.auth?.data && state?.auth?.data?.jwt) {
//     console.log("state.auth.data.jwt=",state.auth.data.jwt);
//     headers["Authorization"] = `Bearer ${state.auth.data.jwt}`;
//   } else {
//     headers["Authorization"] = "";
//   }



//   // Create Axios instance
//   return axios.create({
//     baseURL: apiUrl, // Base URL for your requests
//     timeout: 20000,
//     headers: headers,
//     responseType: "json"
//   });
// }


import axios from "axios";
import { AUTH_TOKEN } from "../constants/authenticationConstants";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_DOMAIN_URL;

export default function generateApiClient(getState) {
  const apiClient = axios.create({
    baseURL: apiUrl, // Base URL for your requests
    timeout: 20000,
    responseType: "json",
  });

  // Add a request interceptor
  apiClient.interceptors.request.use(
    (config) => {
      const state = getState();
      const jwtToken = state.auth.data && state.auth.data.jwt

      // If the request is a GET request and no token is found, reject it
      if (config.method === 'get' && !jwtToken) {
        return new Promise(() => { })
      }

      // Set the Authorization header if token is found
      if (jwtToken) {
        config.headers["Authorization"] = `Bearer ${jwtToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return apiClient;
}
