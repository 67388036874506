// import PropTypes from 'prop-types';
// import React from "react";
// import { Link } from "react-router-dom";

// import { createBrowserHistory } from 'history';

// import _ from "lodash";
// import { Button, Dropdown, Icon } from "semantic-ui-react";
// // import Logo from "../layout/logo";
// import NavLink from "./navLink";
// import css from "../../assets/styles/layout/nav.module.scss";
// import Logo from '../layout/logo';

// const browserHistory = createBrowserHistory();

// const { object } = PropTypes;

// class Navigation extends React.Component {
//   static propTypes = {
//     user: object
//   };

//   handleUserDropdownClick = (path) => {
//     return () => {
//       browserHistory.push(path);
//       if(path === "/logout"){

//       }
//     };
//   };

//   render() {
//     return (
//       <nav className={css.navigation}>
//         <div className={css.container}>
//           <Logo className={css.logo} sendClass={css.logoSend} />
//           {this.renderLoggedInLinks()}
//           {this.renderUserDropdown()}
//           {this.renderLoggedOutLinks()}
//         </div>
//       </nav>
//     );
//   }

//   renderUserDropdown = () => {
//     if (!this.props.user) {
//       return null;
//     }

//     const trigger = (
//       <div>
//         <span className={css.userProfileIcon}>
//           <i className="fa fa-user-circle-o" />
//         </span>
//         <span className={css.userProfileText}>
//           {this.props.user.email}
//         </span>
//       </div>
//     );

//     const settings = (
//       <span>
//         <Icon name="settings" />
//         Settings
//       </span>
//     );

//     const signOut = (
//       <span>
//         <Icon name="sign out" />
//         Sign Out
//       </span>
//     );

//     return (
//       <Dropdown trigger={trigger} pointing="top right" icon={null}>
//         <Dropdown.Menu>
//           <Dropdown.Item onClick={this.handleUserDropdownClick("/settings")}>
//             {settings}
//           </Dropdown.Item>
//           <Dropdown.Item onClick={this.handleUserDropdownClick("/logout")}>
//             {signOut}
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     );
//   };

//   renderLoggedInLinks = () => {
//     if (this.props.user) {
//       const subaccountFeature = _.get(this.props,"user.features.subaccount",false);
//       const isCompany = _.get(this.props,"user.is_company",false);
//       const subaccountId = _.get(this.props,"user.subaccount_id");
//       return (
//         <ul>
//           <li key="dashboard"><NavLink to="/dashboard">Home</NavLink></li>
//           <li key="timers"><NavLink to="/timers">Timers</NavLink></li>
//           <li key="templates"><NavLink to="/templates">Templates</NavLink></li>
//           {subaccountFeature && ( isCompany || subaccountId == 0 ) && <li key="accounts"><NavLink to="/accounts">Accounts</NavLink></li>}
//           <li key="settings"><NavLink to="/settings">Settings</NavLink></li>
//           <li key="faq"><NavLink to="/faq">FAQs</NavLink></li>
//         </ul>
//       );
//     }
//   };

//   renderLoggedOutLinks = () => {
//     if (!this.props.user) {
//       return (
//         <div className={css.loggedOutLinks}>
//           <Link to="/login"><Button primary key="login">Sign In</Button></Link>
//           <Link to="/register"><Button secondary key="register">Sign Up</Button></Link>
//         </div>
//       );
//     }
//   };
// }

// export default Navigation;

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import NavLink from "./navLink";
import css from "../../assets/styles/layout/nav.module.scss";
import Logo from "../layout/logo";
import Cookies from "js-cookie";

import _ from "lodash";
import { AUTH_TOKEN } from "../../constants/authenticationConstants";

const Navigation = ({ user, authToken }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCookie = Cookies.get(AUTH_TOKEN)



  function getAllCookies() {
    const cookies = document.cookie.split('; ');
    const cookieObj = {};
    cookies.forEach(cookie => {
      const [name, value] = cookie.split('=');
      cookieObj[name] = decodeURIComponent(value);
    });
    console.log("cookies", cookieObj)
    return cookieObj;
  }

  getAllCookies()

  console.log("auth token", getCookie)
  const handleUserDropdownClick = (path) => {
    return () => {
      navigate(path);
      if (path === "/logout") {
        // dispatch(logout({ navigate, redirect: '/login' }));
        navigate(path);
        window.localStorage.clear();
        Cookies.remove(AUTH_TOKEN, {
          domain: process.env.REACT_APP_TOKEN_DOMAIN || ".sendtric.com"
        });
        Cookies.remove("token", {
          domain: process.env.REACT_APP_TOKEN_DOMAIN || ".sendtric.com"
        });
      } else {
        navigate(path);
      }
    };
  };





  const renderUserDropdown = () => {

    if (!user && !authToken) {
      return null;
    }

    const trigger = (
      <div>
        <span className={css.userProfileIcon}>
          <i className="fa fa-user-circle-o" />
        </span>
        <span className={css.userProfileText}>{user?.email}</span>
      </div>
    );

    const settings = (
      <span>
        <Icon name="settings" />
        Settings
      </span>
    );

    const signOut = (
      <span>
        <Icon name="sign out" />
        Sign Out
      </span>
    );

    return (
      <Dropdown trigger={trigger} pointing="top right" icon={null}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleUserDropdownClick("/settings")}>
            {settings}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleUserDropdownClick("/logout")}>
            {signOut}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderLoggedInLinks = () => {
    const path = window.location.pathname;

    if (user && authToken) {
      const subaccountFeature = _.get(user, "features.subaccount", false);
      const isCompany = _.get(user, "is_company", false);
      const subaccountId = _.get(user, "subaccount_id");
      return (
        <ul>
          <li key="dashboard">
            <NavLink
              to="/dashboard"
              activeClass={path === "/dashboard" && css.nav_active}
            >
              Home
            </NavLink>
          </li>
          <li key="timers">
            <NavLink
              to="/timers"
              activeClass={path === "/timers" && css.nav_active}
            >
              Timers
            </NavLink>
          </li>
          <li key="templates">
            <NavLink
              to="/templates"
              activeClass={path === "/templates" && css.nav_active}
            >
              Templates
            </NavLink>
          </li>
          {subaccountFeature && (isCompany || subaccountId == 0) && (
            <li key="accounts">
              <NavLink
                to="/accounts"
                activeClass={path === "/accounts" && css.nav_active}
              >
                Accounts
              </NavLink>
            </li>
          )}
          <li key="settings">
            <NavLink
              to="/settings"
              activeClass={path === "/settings" && css.nav_active}
            >
              Settings
            </NavLink>
          </li>
          <li key="faq">
            <NavLink to="/faq" activeClass={path === "/faq" && css.nav_active}>
              FAQs
            </NavLink>
          </li>
        </ul>
      );
    }
  };

  const renderLoggedOutLinks = () => {
    const path = window.location.pathname;

    if (!user && !authToken) {
      return (
        <div className={css.loggedOutLinks}>
          <Link to="/">
            <Button
              style={{ backgroundColor: path === "/" ? "#27292a" : "#2185d0" }}
              primary
              key="login"
            >
              Sign In
            </Button>
          </Link>
          <Link to="/register">
            <Button
              style={{
                backgroundColor: path === "/register" ? "#27292a" : "#2185d0",
              }}
              secondary
              key="register"
            >
              Sign Up
            </Button>
          </Link>
        </div>
      );
    }
  };

  return (
    <nav
      className={css.navigation}
      style={{ boxShadow: "0 1px 2px rgba(0,0,0,.2)" }}
    >
      <div className={css.container}>
        <Logo className={css.logo} sendClass={css.logoSend} />
        {renderLoggedInLinks()}
        {renderUserDropdown()}
        {renderLoggedOutLinks()}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  user: PropTypes.object,
};

export default Navigation;
