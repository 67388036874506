import { LOCATION_CHANGE } from "react-router-redux";
import _ from "lodash";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD_FAILURE,
  BETA_SIGNUP_SUCCESS,
  BETA_SIGNUP_FAILURE,
  AUTH_SUBSCRIBE_SUCCESS,
  AUTH_SUBSCRIBE_FAILURE,
  AUTH_INVOICES_SUCCESS,
  AUTH_INVOICES_FAILURE,
  SUBSCRIBE_UPDATE_SUCCESS,
  SUBSCRIBE_UPDATE_FAILURE,
  AUTH_IN_PROGRESS,
  UPDATE_PASSWORD_FAILURE,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_ACCOUNT_ACTIVATION_SUCCESS,
  USER_ACCOUNT_ACTIVATION_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  CLEAR_AUTH_ERROR,
  FORGOT_PASSWORD_REQUEST,
  UPDATE_CAN_EDIT_TEMPLATES,
  COOKIE_NOT_FOUND,
} from "../constants/authenticationConstants";

//import { UPDATE_USER_DEFAULT_FONT_SUCCESS } from "../constants/customFontConstants";

export const initialState = {
  data: null,
  invoices: null,
  error: null,
  inProgress: false,
  filePath: null,
  forgotPasswordSuccess: false,
  isForgotPasswordRequest: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return { ...state, data: action.data, error: null };
    case AUTH_LOGIN_FAILURE:
      return { ...state, data: null, error: action.error };
    case AUTH_REGISTER_SUCCESS:
      console.log("state=", state, "action=", action);
      return { ...state, data: action.data, error: null };
    case USER_FETCH_SUCCESS:
      let new_Data = _.cloneDeep(action.data);
      console.log("action_Data=", new_Data);

      return { ...state, data: new_Data, error: null };
    case USER_FETCH_FAILURE:
      return { ...state, error: action.error };
    case USER_ACCOUNT_ACTIVATION_SUCCESS:
      let new_Data_New = _.cloneDeep(action.data);
      console.log("action_Data_New=", new_Data_New);
      return { ...state, data: new_Data_New, error: null };
    case USER_ACCOUNT_ACTIVATION_FAILURE:
      return { ...state, error: action.error };
    case BETA_SIGNUP_SUCCESS:
      return { ...state, data: null, error: null };
    case AUTH_REGISTER_FAILURE:
    case BETA_SIGNUP_FAILURE:
      return { ...state, data: null, error: action.error };
    case AUTH_LOGOUT_SUCCESS:
      return { ...state, data: null, error: null };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isForgotPasswordRequest: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: null,
        error: null,
        forgotPasswordSuccess: true,
        isForgotPasswordRequest: false,
      };
    case AUTH_LOGOUT_FAILURE:
      return { ...state, data: null, error: action.error };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        data: null,
        error: action.error,
        isForgotPasswordRequest: false,
      };
    case RESET_PASSWORD_FAILURE:
      return { ...state, data: null, error: action.error };
    case UPDATE_PASSWORD_FAILURE:
      return { ...state, error: action.error };
    case AUTH_SUBSCRIBE_SUCCESS:
      return { ...state, data: action.data, error: null, inProgress: false };
    case AUTH_SUBSCRIBE_FAILURE:
      return { ...state, error: action.error, inProgress: false };
    case AUTH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.data,
        error: null,
        inProgress: false,
      };
    case AUTH_INVOICES_FAILURE:
      return { ...state, error: action.error, inProgress: false };
    case AUTH_IN_PROGRESS:
      return { ...state, inProgress: true };
    case SUBSCRIBE_UPDATE_SUCCESS:
      return { ...state, data: action.data, error: null, inProgress: false };
    case SUBSCRIBE_UPDATE_FAILURE:
      return { ...state, error: action.error, inProgress: false };

    case LOCATION_CHANGE:
      return { ...state, error: null };
    case CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: null,
      };

    case UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        filePath: action.filePath,
        progress: null,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.error,
        progress: null,
      };
    case UPDATE_CAN_EDIT_TEMPLATES:
      return {
        ...state,
        data: {
          ...state.data,
          can_edit_templates: action.data,
        },
      };

    case COOKIE_NOT_FOUND:
      return {
        state: undefined,
        error: null,
      }

    default:
      return state;
  }
};
